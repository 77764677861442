import * as React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import TermsOfUse from '../components/TermsOfUse';
import SEO from '../components/SEO';

export default function Terms(props) {
	return (
		<SEO {...props} title="Terms of Service | Buddy">
			<main>
				<Header />
				<TermsOfUse />
				<div className="mt-24">
					<Footer />
				</div>
			</main>
		</SEO>
	);
}
